import { AuthService } from 'src/app/core/services/auth.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MOVEUP_API_SERVICES } from 'src/app/core/services/moveup-api';
import { PermissionService } from 'src/app/core/services/permission.service';
import { StorageService } from 'src/app/core/services/storage.service';

export const CORE_SERVICES = [
    ...MOVEUP_API_SERVICES,
    AuthService,
    StorageService,
    ExcelService,
    PermissionService,
];
