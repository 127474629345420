import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUserResponseDto } from 'src/app/core/services/moveup-api/common/user/user.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonUserService {
    baseUrl = `${environment.api.baseUrl}/admin/common/user`;

    constructor(private http: HttpClient) {}

    getList(): Observable<CommonUserResponseDto[]> {
        return this.http.get<CommonUserResponseDto[]>(`${this.baseUrl}/list`);
    }

    getCommercials(): Observable<CommonUserResponseDto[]> {
        return this.http.get<CommonUserResponseDto[]>(`${this.baseUrl}/commercials`);
    }
}
