import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionIdentifierType } from 'src/app/core/services/moveup-api/users/roles/roles.dtos';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) {}

    checkPermission(requiredPermissions: PermissionIdentifierType[] | null): Observable<boolean> {
        if (!requiredPermissions || requiredPermissions.length === 0) {
            return of(true);
        }

        return this.authService.hasAnyPermission(requiredPermissions).pipe(
            map((hasPermission) => {
                if (!hasPermission) {
                    void this.router.navigate(['/access-denied']);

                    return false;
                }

                return true;
            }),
        );
    }
}
