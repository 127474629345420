<nz-card [nzBordered]="false">
    <nz-result
        nzStatus="404"
        nzTitle="404"
        nzSubTitle="Sorry, the page you visited does not exist."
    >
        <div nz-result-extra>
            <a routerLink="/" nz-button nzType="primary">Back to home page</a>
        </div>
    </nz-result>
</nz-card>
