<nz-card [nzBordered]="false">
    <nz-result
        nzStatus="403"
        nzTitle="403"
        nzSubTitle="Sorry, you are not authorized to access this page."
    >
        <div nz-result-extra>
            <a routerLink="/" nz-button nzType="primary">Back to home page</a>
        </div>
    </nz-result>
</nz-card>
