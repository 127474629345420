import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as moment from 'moment';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { SHARED_COMPONENTS } from 'src/app/shared/components';
import { SHARED_DIRECTIVES } from 'src/app/shared/directives';
import { SHARED_PIPES } from 'src/app/shared/pipes';
import { SHARED_SERVICES } from 'src/app/shared/services';

moment.locale('en', {
    calendar: {
        lastDay: '[Yesterday at] HH:mm',
        sameDay: '[Today at] HH:mm',
        nextDay: '[Tomorrow at] HH:mm',
        lastWeek: '[Last] dddd [at] HH:mm',
        nextWeek: 'dddd [at] HH:mm',
        sameElse: 'DD/MM/YYYY [at] HH:mm',
    },
});

const NZ_MODULES = [
    NzGridModule,
    NzSpinModule,
    NzModalModule,
    NzMenuModule,
    NzSpaceModule,
    NzIconModule,
    NzSliderModule,
    NzButtonModule,
    NzLayoutModule,
    NzSelectModule,
    NzDatePickerModule,
    NzDividerModule,
    NzSkeletonModule,
    NzNotificationModule,
    NzToolTipModule,
    NzAutocompleteModule,
    NzMenuModule,
    NzBreadCrumbModule,
    NzCardModule,
    NzFormModule,
    NzInputModule,
    NzAlertModule,
    NzTableModule,
    NzTagModule,
    NzRadioModule,
    NzPageHeaderModule,
    NzInputNumberModule,
    NzSwitchModule,
    NzCheckboxModule,
    NzMessageModule,
    NzDropDownModule,
    NzTypographyModule,
    NzProgressModule,
    NzAvatarModule,
    NzRateModule,
    NzUploadModule,
    NzDescriptionsModule,
    NzPopoverModule,
    NzEmptyModule,
    NzColorPickerModule,
    NzTabsModule,
    NzBadgeModule,
    NzStatisticModule,
    NzImageModule,
    NzPopconfirmModule,
    NzFlexModule,
    NzResultModule,
];

const SHARED_MODULES = [
    CommonModule,
    ...NZ_MODULES,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    MomentModule,
    InfiniteScrollModule,
    AngularSvgIconModule,
    DragDropModule,
];

const SHARED_IMPORTS_MODULES = [
    ...SHARED_MODULES,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
];

const SHARED_EXPORTS_MODULES = [...SHARED_MODULES, NgxMaskModule];

@NgModule({
    imports: [...SHARED_IMPORTS_MODULES, HttpClientModule],
    declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
    providers: [...SHARED_SERVICES],
    exports: [
        ...SHARED_EXPORTS_MODULES,
        ...SHARED_COMPONENTS,
        ...SHARED_DIRECTIVES,
        ...SHARED_PIPES,
    ],
})
export class SharedModule {}
