import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, interval, Observable, of, switchMap, take, tap } from 'rxjs';
import { Globals } from 'src/app/core/services/globals';
import { PermissionIdentifierType } from 'src/app/core/services/moveup-api/users/roles/roles.dtos';
import { PermissionService } from 'src/app/core/services/permission.service';
import { documentReadyStateComplete$ } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
    player: AnimationPlayer;

    constructor(
        private animationBuilder: AnimationBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private globals: Globals,
        private permissionService: PermissionService,
    ) {}

    ngOnInit(): void {
        this._handleSplashScreen();
        this._handlePageTitle();
        this._handlePermissions();
    }

    private _handleSplashScreen(): void {
        const splashScreen: HTMLElement | null = document.querySelector('.Splash');
        if (splashScreen) {
            if (!environment.splashScreen) {
                splashScreen.remove();

                return;
            }

            const splashScreenDuration = 1500;
            const animationDuration = 400;

            this.player = this.animationBuilder
                .build(animate(animationDuration, style({ opacity: 0 })))
                .create(splashScreen);

            combineLatest([
                documentReadyStateComplete$,
                interval(splashScreenDuration).pipe(take(1)),
            ]).subscribe(() => {
                this.player.play();

                setTimeout(() => {
                    splashScreen.remove();
                }, animationDuration);
            });
        }
    }

    private _handlePageTitle(): void {
        const appTitle = this.globals.APP_TITLE;
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.titleService.setTitle(appTitle);
            this._setPageTitle(appTitle, this.route);
        });
    }

    private _setPageTitle(appTitle: string, route: ActivatedRoute): void {
        if (route.snapshot.data['title']) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const title = `${route.snapshot.data['title']} - ${appTitle}`;
            this.titleService.setTitle(title);
        }
        if (route.firstChild) {
            return this._setPageTitle(appTitle, route.firstChild);
        }
    }

    async wait(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    private _handlePermissions(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                switchMap(() => this._getRequiredPermissions(this.route)),
                switchMap((requiredPermissions) =>
                    this.permissionService.checkPermission(requiredPermissions).pipe(
                        tap((hasAccess) => {
                            if (!hasAccess) {
                                void this.router.navigate(['/access-denied'], { replaceUrl: true });
                            }
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    private _getRequiredPermissions(
        route: ActivatedRoute,
    ): Observable<PermissionIdentifierType[] | null> {
        let activeRoute = route;
        while (activeRoute.firstChild) {
            activeRoute = activeRoute.firstChild;
        }

        return of((activeRoute.snapshot.data['permissions'] as PermissionIdentifierType[]) || null);
    }
}
