import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';

@Component({
    selector: 'app-layout-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
    openMap = {
        accumulators: false,
        bookmakers: false,
        users: false,
        serp: false,
        scraping: false,
        affiliateAccounts: false,
        localization: false,
    };

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                map((event: NavigationStart) => event.url),
                startWith(this.router.url),
            )
            .subscribe((url: string) => {
                this.openMap.accumulators = RegExp('^/accumulators').test(url);
                this.openMap.bookmakers = RegExp('^/bookmakers').test(url);
                this.openMap.users = RegExp('^/users').test(url);
                this.openMap.serp = RegExp('^/serp').test(url);
                this.openMap.scraping = RegExp('^/scraping').test(url);
                this.openMap.affiliateAccounts = RegExp('^/affiliate-accounts').test(url);
                this.openMap.localization = RegExp('^/localization').test(url);
            });
    }
}
