import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { PermissionIdentifierType } from 'src/app/core/services/moveup-api/users/roles/roles.dtos';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
    @Input('hasPermission') permissions!: PermissionIdentifierType | PermissionIdentifierType[];

    constructor(
        private authService: AuthService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this._checkPermissions().subscribe((hasPermission) => {
            this.viewContainer.clear();
            if (hasPermission) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });
    }

    private _checkPermissions(): Observable<boolean> {
        const permissionsArray = Array.isArray(this.permissions)
            ? this.permissions
            : [this.permissions];

        return combineLatest([
            this.authService.isAdmin(),
            this.authService.getUserPermissions(),
        ]).pipe(
            map(
                ([isAdmin, userPermissions]) =>
                    isAdmin ||
                    permissionsArray.some((permission) => userPermissions.includes(permission)),
            ),
        );
    }
}
